import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import { convertFromSecondsToTime } from '../../functions/common'
import classes from '../../styles/player.module.scss'
import cn from 'classnames'
import { Loader } from '../loader'
import { CustomIcon } from '../customIcon/customIcon'
import { CustomButtonSelect } from '../customButtonSelect'

export type THandlePlayerChangeProps = {
  waveSurfer: WaveSurfer
  totalTime: number
  currentTime: number
  remainingTime: number
}

const context = new AudioContext()

export const PlayerV2: FC<{
  url: string
  handlePlay?: (waveSurfer: WaveSurfer) => void
  handlePause?: (waveSurfer: WaveSurfer) => void
  handleChange?: (opts: THandlePlayerChangeProps) => void
  handleOnSeek?: (opts: THandlePlayerChangeProps) => void
  stopPlayer?: boolean
  setPlayer?: Dispatch<SetStateAction<WaveSurfer | null>>
  classes?: { 
    player?: string, 
    volumeBtn?: string,
    playPauseBtn?: string,
    playerTime?: string
  }
  containerWidth?: number,
  barWidth?: number,
  waveColor?: string,
  primaryColor?: string
}> = ({
  containerWidth,
  url,
  handlePlay,
  handlePause,
  setPlayer,
  handleChange,
  handleOnSeek,
  stopPlayer,
  classes: customClasses,
  waveColor = '#E8E8EC',
  primaryColor,
  barWidth = 3
}) => {
  const waveSurfer = useRef<null | WaveSurfer>(null)
  const waveForm = useRef(null)
  const [{ isPlaying, currentTime, totalTime, isReady, volume }, setWaveSurferParams] = useState<{
    isReady: boolean
    isPlaying: boolean
    currentTime?: number
    totalTime?: number
    remainingTime?: number
    volume: number
  }>({
    isReady: false,
    isPlaying: false,
    currentTime: undefined,
    totalTime: undefined,
    remainingTime: undefined,
    volume: 0.7,
  })

  const play = useCallback(() => {
    if (waveSurfer?.current) {
      waveSurfer.current?.play()
      setWaveSurferParams((p) => ({ ...p, isPlaying: true }))
      handlePlay && handlePlay(waveSurfer.current)
    }
  }, [handlePlay])

  const pause = useCallback(() => {
    if (waveSurfer?.current) {
      waveSurfer.current?.pause()
      setWaveSurferParams((p) => ({ ...p, isPlaying: false }))
      handlePause && handlePause(waveSurfer.current)
    }
  }, [handlePause])

  useEffect(() => {
    const primary = primaryColor || getComputedStyle(document?.documentElement)?.getPropertyValue('--base-primary-600')
    if (waveForm.current) {
      const startPlayer = async () => {
        waveSurfer.current = WaveSurfer.create({
          audioContext: context,
          audioScriptProcessor: new AnalyserNode(context) as any,
          container: waveForm.current as any,
          waveColor,
          progressColor: primary || '#000000',
          cursorColor: 'transparent',
          backend: 'MediaElement',
          barWidth,
          barRadius: 3,
          barMinHeight: 2,
          minPxPerSec: 10,
          fillParent: true,
          normalize: true,
          height: 25,
          hideScrollbar: true
        })

        setPlayer && setPlayer(waveSurfer.current)
        waveSurfer.current?.load(url)
        waveSurfer.current?.setVolume(volume)

        waveSurfer.current?.on('ready', () => {
          const totalTime = waveSurfer.current?.getDuration()
          const currentTime = waveSurfer.current?.getCurrentTime()
          setWaveSurferParams((p) => ({ ...p, isReady: true, currentTime, totalTime }))
        })

        waveSurfer.current?.on('audioprocess', () => {
          if (waveSurfer.current?.isPlaying()) {
            const totalTime = waveSurfer.current?.getDuration()
            const currentTime = waveSurfer.current?.getCurrentTime()
            const remainingTime = totalTime - currentTime
            setWaveSurferParams((p) => ({ ...p, totalTime, currentTime, remainingTime, isPlaying: true }))
            handleChange && handleChange({ waveSurfer: waveSurfer.current, totalTime, currentTime, remainingTime })
          }
        })

        waveSurfer.current?.on('finish', () => {
          setWaveSurferParams((p) => ({ ...p, isPlaying: false, currentTime: 0 }))
          waveSurfer.current?.seekTo(0)
        })

        waveSurfer.current?.on('seek', () => {
          if (!waveSurfer.current) return
          const totalTime = waveSurfer.current?.getDuration()
          const currentTime = waveSurfer.current?.getCurrentTime()
          const remainingTime = totalTime && currentTime && totalTime - currentTime
          setWaveSurferParams((p) => ({ ...p, totalTime, currentTime, remainingTime }))
          handleOnSeek && handleOnSeek({ waveSurfer: waveSurfer.current, totalTime, currentTime, remainingTime })
        })
      }

      startPlayer()
    }

    return () => {
      waveSurfer.current?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange, handleOnSeek, setPlayer, url, containerWidth, isReady])

  useEffect(() => {
    if (stopPlayer && waveSurfer?.current?.isPlaying()) {
      waveSurfer.current?.pause()
      setWaveSurferParams((p) => ({ ...p, isPlaying: false }))
      handlePause && waveSurfer.current && handlePause(waveSurfer.current)
    }
  }, [handlePause, stopPlayer])

  if (!url) return null

  return (
    <>
      {!isReady && <Loader />}
      <div
        style={!isReady ? { width: 0, visibility: 'hidden' } : {}}
        className={cn(classes.player, customClasses?.player && customClasses?.player)}
      >
        {!isPlaying ? (
          <button className={cn(classes.playPauseBtn, 'playPauseBtn', customClasses?.playPauseBtn)} onClick={play}>
            <CustomIcon icon="play" />
          </button>
        ) : (
          <button className={cn(classes.playPauseBtn, 'playPauseBtn', customClasses?.playPauseBtn)} onClick={pause}>
            <CustomIcon icon="pause" />
          </button>
        )}
        <div className={cn(classes.playerTime, 'currentTime', customClasses?.playerTime)}>
          {currentTime !== undefined && convertFromSecondsToTime(Math.round(currentTime))}
        </div>
        <div className={cn(classes.waveForm, 'waveForm')} ref={waveForm} />
        <div className={cn(classes.playerTime, classes.right, 'totalTime', customClasses?.playerTime)}>
          {totalTime !== undefined && convertFromSecondsToTime(Math.round(totalTime))}
        </div>
        <CustomButtonSelect
          className={cn(classes.volumeBtn, 'volumeBtn', customClasses?.volumeBtn)}
          style={{ padding: 0 }}
          contentComponent={
            <div className={classes.volumeRange}>
              <input
                style={{
                  background: `linear-gradient(to right, #013b8e ${(volume / 1) * 100}%, #0015350D ${
                    (volume / 1) * 100
                  }%)`,
                }}
                value={volume}
                className={classes.playerRange}
                onChange={(e) => {
                  waveSurfer.current?.setVolume(+e.target.value)
                  setWaveSurferParams((p) => ({ ...p, volume: +e.target.value }))
                }}
                step={0.1}
                min={0}
                max={1}
                type="range"
              />
            </div>
          }
        >
          <CustomIcon icon="volume" />
        </CustomButtonSelect>
      </div>
    </>
  )
}
