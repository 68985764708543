import { FC, useState } from 'react'
import { IVoice } from '../../../@types/tts'
import classes from '../../../styles/tts/ttsSidebarVoices.module.scss'
import msgClasses from '../../../styles/tts/ttsMessages.module.scss'
import { LanguageIcons } from '../../../components/languageIcons'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { Skeleton } from '../../../components/skeleton'
import { useDispatch } from '../../../hooks/redux'
import { ttsSetRemovePickedVoice } from '../../../store/slices/tts/ttsSlice'
import cn from 'classnames'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'

import defaultAvatar from '../../../assets/defaultUser.png'


export const SidebarOverflowIndicator: FC<{
    voices: IVoice[]
}> = ({ voices }) => {
    const dispatch = useDispatch()
    const [hoveredVoice, setHoveredVoice] = useState<IVoice | null>(null)

    const handleMouseEnter = (voice: IVoice) => {
        setHoveredVoice(voice)
    }

    const handleMouseLeave = () => {
        setHoveredVoice(null)
    }

    const handleRemoveVoice = (voice: IVoice) => {
        dispatch(ttsSetRemovePickedVoice({ name: voice.name }))
    }

    return (voices.length > 0 ? (
        <PortalTooltip
            classes={{ contentClassName: classes.ttsOverflowTooltipContent }}
            triggerMode='click'
            placement='bottom-middle'
            tooltipContent={
                <div className={classes.ttsOverflowVoicesWrapper}>
                    <div className={classes.ttsOverflowVoicesTooltip}>
                        {voices?.map((voice, index) => {
                            const avatar = voice.avatar || defaultAvatar

                            return (
                                <div
                                    onMouseEnter={() => handleMouseEnter(voice)}
                                    onMouseLeave={handleMouseLeave}
                                    key={voice.name + index + 'sidebarOverflow'}
                                    className={classes.ttsOverflowVoiceItem}
                                >
                                    <div className={classes.ttsAvatarWraper}>
                                        <div className={msgClasses.ttsAvatar}>
                                            {avatar
                                                ? <img draggable={false} src={avatar} alt='' />
                                                : <Skeleton type='avatar' />
                                            }
                                        </div>
                                        <div className={classes.voiceName}>
                                            {voice.title}
                                            {/* {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt='emotion' />} */}
                                        </div>
                                    </div>
                                    <div className={classes.ttsOverflowVoicesOtherInfo}>
                                        {hoveredVoice !== voice ? (
                                            <LanguageIcons languages={voice.languages} />
                                        ) : (
                                            <button
                                                className={classes.ttsOverflowVoicesCrossBtn}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    handleRemoveVoice(voice)
                                                }}>
                                                <CustomIcon icon='cross' />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }>
            <div className={cn(classes.ttsVoiceItem, classes.ttsSidebarOverflowBtn)}>
                +{voices.length}
            </div>
        </PortalTooltip>
        ) : null
    )
}
