import { memo, useMemo, useRef, useState, useEffect, FC } from 'react'
import cn from 'classnames'

import { ttsSetRemovePickedVoice, ttsUpdatePickedVoices, ttsSetEmotionVoice } from '../../../store/slices/tts/ttsSlice'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { LanguageIcons } from '../../../components/languageIcons'
import { languageTranslate } from '../../asr/settings/config'
import { useSelector, useDispatch } from '../../../hooks/redux'
import { TtsModal } from '../ttsModal/ttsModal'
import { EmotionList } from '../emotionList'
import { IVoice } from '../../../@types/tts'
import classes from '../../../styles/tts/ttsSidebarVoices.module.scss'
import modalClasses from '../../../styles/tts/ttsModal.module.scss'
import addButton from '../../../assets/icons/addVoice.svg'
import closeButton from '../../../assets/icons/close.svg'
import star from '../../../assets/icons/star.svg'
import { useMediaQuery } from 'react-responsive'
import { Skeleton } from '../../../components/skeleton'
import { SidebarOverflowIndicator } from './sidebarOverflowIndicator'

import defaultAvatar from '../../../assets/defaultUser.png'


const SidebarVoicesTooltipContent: FC<{ voice: IVoice }> = ({ voice }) => {
  const { title, languages, sex, emotion } = voice
  return (
    <div className={classes.ttsTooltipContent}>
      {sex !== '' && (
        <div className={classes.tooltipOtherInfo}>
          <span className={cn(classes.tooltipSex, sex === 'female' && classes.tooltipFemale)}>
            <CustomIcon icon={sex} />
          </span>
        </div>
      )
      }
      <LanguageIcons languages={languages} />
      <div className={classes.tooltipName}>
        {title}
        {emotion && <img className={modalClasses.ttsVoiceEmotionMark} src={star} alt="emotion" />}
      </div>
    </div>
  )
}

export const SidebarVoices = () => {
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const voices = useSelector((state) => state.tts.voices)
  const ttsMessages = useSelector((state) => state.tts.messages)
  const isSidebarSmall = useSelector((state) => state.tts.messages.length > 0)
  const [showModal, setShowModal] = useState(false)
  const [prevVoices, setPrevVoices] = useState(JSON.parse(JSON.stringify(voices)))
  const [hoveredVoice, setHoveredVoice] = useState<IVoice | null>(null)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const pickedVoices = useMemo(() => voices.filter((item) => item.picked), [voices])

  const [maxVoicesInSidebar, setMaxVoicesInSidebar] = useState<number>(pickedVoices.length)
  const [showEmotions, setShowEmotions] = useState<boolean>(false)

  const handleShowModal = () => {
    if (showModal) {
      dispatch(ttsUpdatePickedVoices(prevVoices))
    } else {
      setPrevVoices(JSON.parse(JSON.stringify(voices)))
    }
    setShowModal((p) => !p)
  }

  const handleActiveVoice = (voice: IVoice) => {
    dispatch(ttsSetRemovePickedVoice({ name: voice.name }))
  }

  const handleMouseEnter = (voice: IVoice | null) => {
    setHoveredVoice(voice)
  }

  const handleMouseLeave = () => {
    setHoveredVoice(null)
  }

  const handleShowEmotions = () => {
    setShowEmotions(!showEmotions)
  }

  useEffect(() => {
    const handleResize = () => {
      if (!ref.current || !ref.current.children.length) return
      const sidebarWidth = ref.current.offsetWidth
      const children = ref.current.children
      let totalWidth = 0

      for (let i = 0; i < children.length; i++) {
        const element = children[i] as HTMLElement
        const offsetWidth = element.offsetWidth 
        totalWidth += offsetWidth + 25
      }

      const overflowBtnWidth = isSidebarSmall || isLaptopOrMobile ? 45 : 120
      const averageItemWidth = totalWidth / children.length

      setMaxVoicesInSidebar(Math.floor((sidebarWidth - overflowBtnWidth) / averageItemWidth))
    }

    setTimeout(handleResize, 500)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [pickedVoices, isSidebarSmall, isLaptopOrMobile])

  const visiblePickedVoices = useMemo(() => {
    return maxVoicesInSidebar > 0 ? pickedVoices.slice(0, maxVoicesInSidebar) : []
  }, [maxVoicesInSidebar, pickedVoices])
  console.log(showEmotions)
  return (
    <div className={cn(classes.ttsSidebarHeaderWrapper, (isSidebarSmall || isLaptopOrMobile) && classes.sidebarSmall)}>
      <button onClick={handleShowModal} className={cn(classes.ttsVoiceItem, classes.ttsAddVoiceBtn)}>
        <img src={addButton} alt="" />
      </button>
      <div ref={ref} className={classes.ttsSidebarVoicesWrapper}>
        {visiblePickedVoices?.map((voice, index) => {
          const languages = voice.languages
            .map((lng) => languageTranslate[lng].toLowerCase() ?? lng.toLowerCase())
            .join(', ')

          const avatar = voice.avatar || defaultAvatar

          return (
            <div key={index + voice.name + 'sidebar'}>
              <PortalTooltip
                classes={{ contentClassName: classes.voiceTooltipContent }}
                placement="bottom-right"
                tooltipContent={<SidebarVoicesTooltipContent voice={voice} />}
                hideTooltip={showEmotions || hoveredVoice !== voice || !ttsMessages?.length}
              >
                <div
                  key={voice.name + index + 'sidebar'}
                  className={cn(classes.ttsVoiceItem, (isSidebarSmall || isLaptopOrMobile) && classes.ttsItemCollapsed)}
                  onMouseEnter={() => handleMouseEnter(voice)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => {
                    // if (isLaptopOrMobile && voice?.emotion) {
                    //   setShowEmotionDialog(!showEmotionDialog)
                    // }
                  }}
                >
                  <div
                    style={{
                      padding: isSidebarSmall || isLaptopOrMobile ? 0 : 4,
                    }}
                    className={classes.ttsVoiceAvatarWrapper}
                  >
                    <div className={classes.ttsVoiceAvatar}>
                      {avatar ? (
                        <img draggable={false} src={avatar} alt='' />
                      )
                        : <Skeleton type='avatar' />
                      }
                    </div>
                  </div>
                  {voice.emotion && (
                    <button className={classes.ttsVoiceEmotion}>
                      <EmotionList 
                        showEmotions={showEmotions}
                        handleShowEmotions={handleShowEmotions}
                        emotions={voice.availableEmotions} 
                        voice={voice.name} 
                        selectedEmotion={voice.emotion}
                      />
                    </button>
                  )}
                  <div
                    className={cn(
                      classes.ttsVoiceContent,
                      (isSidebarSmall || isLaptopOrMobile) && classes.ttsVoiceContentCollapsed
                    )}
                  >
                    <div className={classes.name}>
                      {voice.title}
                      {voice.emotion && <img className={classes.ttsVoiceEmotionMark} src={star} alt="emotion" />}
                    </div>
                    <div className={classes.languages}>{languages.charAt(0).toUpperCase() + languages.slice(1)}</div>
                  </div>
                  {hoveredVoice === voice && (
                    <button
                      onClick={() => {
                        handleActiveVoice(voice)
                      }}
                      className={classes.ttsCloseVoiceBtn}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  )}
                </div>
              </PortalTooltip>
            </div>
          )
        })}
        <SidebarOverflowIndicator
          voices={(maxVoicesInSidebar - pickedVoices.length !== 1) ? pickedVoices.slice(maxVoicesInSidebar, pickedVoices.length) : []}
        />
      </div>
      <TtsModal
        prevVoices={prevVoices}
        handleClose={handleShowModal}
        showModal={showModal}
        confirmChanges={() => setShowModal(false)}
      />
    </div>
  )
}

SidebarVoices.displayName = 'TtsVoices'
