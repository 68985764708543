import classes from '../../styles/tts/ttsModal.module.scss'
import sidebarClasses from '../../styles/tts/ttsSidebarVoices.module.scss'
import cn from 'classnames'
import { EmotionListConfig } from './config'
import { FC, useCallback, useMemo } from 'react'
import { useDispatch } from '../../hooks/redux'
import { ttsSetEmotionVoice } from '../../store/slices/tts/ttsSlice'
import { PortalTooltip } from '../../components/tooltip/portalTooltip'


export const EmotionList: FC<{
  selectedEmotion: string,
  handleShowEmotions?: () => void
  showEmotions?: boolean,
  emotions: string[] | null
  voice: string
}> = ({ selectedEmotion, voice, emotions, handleShowEmotions = () => {} }) => {
  const dispatch = useDispatch()

  const handleSelectEmotion = useCallback((emotion: string) => {
    dispatch(ttsSetEmotionVoice({ name: voice, emotion }))
  }, [dispatch, voice])

  const undefinedEmoji = useMemo(() => EmotionListConfig.find(el => el.name === 'undefined')?.url, [])
  const filteredEmotions = useMemo(() => {
    return emotions?.map(item => {
      return EmotionListConfig.find(el => el.name === item) || {
        name: item,
        tittle: item,
        url: undefinedEmoji || '',
      }
    })
  }, [emotions])

  return (
    <PortalTooltip
      classes={{ contentClassName: cn(sidebarClasses.ttsOverflowTooltipContent, sidebarClasses.ttsEmotionTooltip) }}
      triggerMode='click'
      placement='bottom-middle'
      tooltipContent={
        <div className={classes.emojiDropdownWrapper}>
          {filteredEmotions?.map(({ name: emotion, url, tittle }) => {
            return (
              <div
                className={cn(classes.emojiDropdownItem, selectedEmotion === emotion && classes.emojiDropdownActiveItem)}
                onClick={(e) => {
                  e.stopPropagation()
                  handleSelectEmotion(emotion)
                }}
              >
                <img className={classes.emojiDropdownImg} src={url} alt="name" />
                <span>{tittle}</span>
              </div>
            )
          })}
        </div>
      }>
      <img
        onClick={handleShowEmotions}
        width={20}
        height={20}
        src={EmotionListConfig.find((item) => item.name === selectedEmotion)?.url || undefinedEmoji}
      />
    </PortalTooltip>
  )
}
